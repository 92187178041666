import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import 'lazysizes';
import Imgix from 'react-imgix';

import { Box } from 'components';

const ImageItemContainer = styled.figure`
  margin: 0;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  overflow: hidden;

  img {
    background-color: #1A1A1A;
    width: 100%;
    filter: blur(24px);
    transition: filter 0.12s linear 0.03s;

    &.lazyloaded {
      filter: none;
    }
  }
`;

const ImageMedia = props => {
  const {
    onMouseOut,
    onMouseOver,
    item,
    data: { img_url }
  } = props;

  return (
    <ImageItemContainer
      onMouseOver={() => onMouseOver(item)}
      onMouseOut={onMouseOut}
    >
      <Imgix
        className="lazyload"
        src={img_url}
        sizes="min(50vw, 400px)"
        attributeConfig={{
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes'
        }}
        htmlAttributes={{
          src: `${img_url}?blur=200&px=64&auto=format&dpr=0.1`
        }}
      />
    </ImageItemContainer>
  );
};

ImageMedia.propTypes = {
  data: PropTypes.object.isRequired
};
export default ImageMedia;
