import React from 'react';
import Box from 'components/Box';
import PropTypes from 'prop-types';
import { mediaQueries } from 'styles/theme';
import styled from '@emotion/styled';

const LiveNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -0.04em;
  margin: 1.4em auto;
  user-select: none;

  ${mediaQueries.sm} {
    font-size: 56px;
    line-height: 72px;
  }

  &.dark {
    color: grey;
  }

  &.light {
    color: black;
  }

  > div > span {
    &::before {
      content: '\\02192';
      display: inline-block;
      max-width: 0;
      opacity: 0;
      transform: scale(0.2);
      transition: max-width 0.2s, opacity 0.1s, transform 0.2s, color 0.2s;
    }

    &.active {
      color: #00ff00;

      &::before {
        max-width: 40px;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;

const NavInner = styled.div`
  cursor: pointer;
  display: inline-flex;
  margin: 0 auto;
`;

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: this.props.active || 'afk' };

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  getInitialState() {
    return { active: 'afk' };
  }

  onClickHandler(evt) {
    const { active } = this.state;
    let newActive = 'afk';
    if (active === 'afk') {
      newActive = 'online';
    }
    this.setState({ active: newActive });

    if (this.props.onChange) {
      this.props.onChange(newActive);
    }
  }

  render() {
    const { active } = this.state;
    const { theme } = this.props;

    return (
      <Box gridColumn="span 12" textAlign="center">
        <LiveNav className={theme}>
          <NavInner onClick={this.onClickHandler}>
            <span id="online" className={active === 'online' ? 'active' : ''}>
              ONLINE
            </span>
            /
            <span id="afk" className={active === 'afk' ? 'active' : ''}>
              AFK
            </span>
          </NavInner>
        </LiveNav>
      </Box>
    );
  }
}

Nav.defaultProps = {
  theme: 'dark'
};

Nav.prototypes = {
  active: PropTypes.string,
  onChange: PropTypes.func,
  theme: PropTypes.string
};

export default Nav;
