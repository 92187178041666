import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { mediaQueries } from 'styles/theme';

import { Box } from 'components';

const TextItemContainer = styled(Box)`
  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (339 / 256));
  }

  &:nth-of-type(5n + 1) > div {
    background: #004000;
    color: black;
    border-radius: 50%;
  }

  &:nth-of-type(5n + 2) > div {
    background: black;
    color: white;
    border: 1px solid white;
  }

  &:nth-of-type(5n + 3) > div {
    background: black;
    color: white;
    border: 1px solid white;
    border-radius: 40px;

    ${mediaQueries.sm} {
      border-radius: 80px;
    }
  }

  &:nth-of-type(5n + 4) > div {
    background: #000080;
    color: white;
  }

  &:nth-of-type(5n + 5) > div {
    background: black;
    color: white;
    border: 1px solid white;
  }
`;

const TextBoxWrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  cursor: pointer;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TextBox = styled(Box)`
  width: 100%;
  display: grid;
  place-items: center;
  height: 346px;

  p {
    margin: 0 2em;
    text-transform: lowercase;
  }
`;

const TextMedia = props => {
  const {
    onMouseOut,
    onMouseOver,
    item,
    data: { text }
  } = props;
  return (
    <TextItemContainer>
      <TextBoxWrapper
        onMouseOver={() => onMouseOver(item)}
        onMouseOut={onMouseOut}
      >
        <TextBox>
          <p>{text}</p>
        </TextBox>
      </TextBoxWrapper>
    </TextItemContainer>
  );
};

TextMedia.propTypes = {
  data: PropTypes.object.isRequired
};

export default TextMedia;
