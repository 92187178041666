import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const VideoItemContainer = styled.figure`
  margin: 0;
  padding: 0;
  line-height: 0;

  video {
    cursor: pointer;
    width: 100%;
  }
`;

class VideoMedia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: false
    };
  }

  onClick = () => {
    if (this.refs.vidRef.paused) {
      this.refs.vidRef.play();
    } else {
      this.refs.vidRef.pause();
    }
  };

  render() {
    const {
      onMouseOut,
      onMouseOver,
      item,
      data: { img_url, video_url }
    } = this.props;

    return (
      <VideoItemContainer
        onClick={this.onClick}
        onMouseOver={() => onMouseOver(item)}
        onMouseOut={onMouseOut}
      >
        <video
          ref="vidRef"
          type="video/mp4"
          className="video-player"
          src={video_url}
          poster={img_url}
          muted
          loop
        ></video>
      </VideoItemContainer>
    );
  }
}

VideoMedia.propTypes = {
  data: PropTypes.object.isRequired,
  playing: PropTypes.bool
};
export default VideoMedia;
