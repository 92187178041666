import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';

import { RichText } from 'prismic-reactjs';

import { LayoutSimple, SEO, Grid, Box, Text } from 'components';

import Nav from 'components/live/Nav';
import MediaFeed from 'components/live/MediaFeed';
import OnlineFeed from 'components/live/OnlineFeed';
import theme from 'styles/theme';

const GeneralContainer = styled(Grid)`
  border-top: 1px solid ${theme.colors.gray};
  background: ${props => props.bg};
  min-height: ${props => `calc(100vh)`};
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0;
`;

class Live extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);

    this.state = {
      data: props.data.prismic,
      section: 'afk',
      onMediaFeedReady: false,
    };

    this.onSubnavChange = this.onSubnavChange.bind(this);
  }

  onSubnavChange(section) {
    this.setState({ section });
  }

  onMediaFeedReady = () => {
    console.log('onMediaFeedReady');
    this.setState({ onMediaFeedReady: true });
  }

  render() {
    const { data, section, onMediaFeedReady } = this.state;

    const metaData = {
      title: RichText.asText([{ text: 'B-Reel — Live' }]),
      description: RichText.asText([{ text: "See what we're up to" }]),
      themeColor: '#000'
    };

    return (
      <LayoutSimple dark={true}>
        <SEO {...metaData} />

        <GeneralContainer bg={section === 'afk' ? 'black' : 'white'}>
          <Nav
            active={section}
            onChange={this.onSubnavChange}
            theme={section === 'afk' ? 'dark' : 'light'}
          />
          <MediaFeed hidden={section !== 'afk'} onMediaFeedReady={this.onMediaFeedReady} />
          <OnlineFeed hidden={section !== 'online'} initConnection={onMediaFeedReady || section === 'online'} />
        </GeneralContainer>
      </LayoutSimple>
    );
  }
}

export default Live;

export const query = graphql`
  {
    prismic {
      allJoin_pages {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            body {
              ... on PRISMIC_Join_pageBodySlide_card {
                primary {
                  paragraph
                }
              }
            }
          }
        }
      }
      allOffices(sortBy: order_ASC) {
        edges {
          node {
            _meta {
              uid
            }
            address
            city
            email
            tel
          }
        }
      }
      allJob_items {
        edges {
          node {
            _meta {
              uid
              type
            }
            title
            office {
              ... on PRISMIC_Office {
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;
