import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import axios from 'axios';
import { mediaQueries } from 'styles/theme';

import { Grid, Box, Text } from 'components';
import TextMedia from 'components/live/TextMedia';
import ImageMedia from 'components/live/ImageMedia';
import VideoMedia from 'components/live/VideoMedia';

const FeedContainer = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  align-items: end;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  grid-gap: 0.5em;
  column-gap: 0.5em;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 0.5em;

  ${mediaQueries.sm} {
    grid-row-gap: 1rem;
  }
`;

const MetadataContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
  color: #00ff00;
  display: none;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -0.04em;

  .time {
    font-family: 'Space Mono';
  }

  ${mediaQueries.md} {
    font-size: 52px;
    line-height: 64px;
  }

  ${mediaQueries.notTouch} {
    display: flex;
  }
`;

class MediaFeed extends React.Component {
  constructor(props) {
    super(props);
    const {
      onMediaFeedReady,
    } = props;
    this.observerInitialized = false;
    this.state = {
      media: [],
      metadata: null
    };
    axios
      .get(process.env.GATSBY_MEDIA_FEED_API)
      .then(resp => {
        onMediaFeedReady();
        const { media } = resp.data;
        if (media && media.length > 0) {
          this.setState({ media });
        }
      })
      .catch(e => {
        onMediaFeedReady();
        console.log('error getting data', e);
      });
  }

  componentDidUpdate() {
    const { media } = this.state;
    if (!this.observerInitialized && media && media.length > 0) {
      this.observerInitialized = true;
      const videos = document.querySelectorAll('.video-player');
      const config = {
        threshold: [0.3]
      };

      const observer = new IntersectionObserver(this.onIntersect, config);

      videos.forEach(videoPlayer => {
        observer.observe(videoPlayer);
      });
    }
  }

  onIntersect = videos => {
    videos.forEach(video => {
      if (video.isIntersecting) {
        video.target.play();
      } else {
        video.target.pause();
      }
    });
  };

  onMouseOverMedia = item => {
    let uploadDate = '';
    if (item.upload_at) {
      const dateOptions = { hour: '2-digit', minute: '2-digit' };
      uploadDate = new Date(item.upload_at * 1000).toLocaleTimeString(
        // Unix timestamp
        'en-US',
        dateOptions
      );
    }
    let uploadLocation = '';
    if (item.time_zone) {
      uploadLocation = item.time_zone.location.split('/')[1].replace('_', ' ');
    }

    let metadata = '';
    if (uploadDate) {
      metadata += `<span class="time">${uploadDate.split(' ')[0]}</span>${
        uploadDate.split(' ')[1]
        }`;
    }
    if (uploadLocation) {
      if (metadata.length > 0) {
        metadata += '—';
      }
      metadata += uploadLocation;
    }

    this.setState({
      metadata
    });
  };

  onMouseOutMedia = () => {
    this.setState({
      metadata: null
    });
  };

  render() {
    const { hidden } = this.props;
    const { media, metadata } = this.state;

    return (
      <Box
        gridColumn="span 12"
        textAlign="center"
        py={[7, 8]}
        style={{
          display: hidden ? 'none' : 'block'
        }}
      >
        {metadata && (
          <MetadataContainer>
            <span dangerouslySetInnerHTML={{ __html: metadata }} />
          </MetadataContainer>
        )}

        <FeedContainer>
          {media.map(item => {
            switch (item.type) {
              case 'text':
                return (
                  <TextMedia
                    key={`media-${item.id || item.upload_at}`}
                    item={item}
                    data={item.media_data}
                    onMouseOver={this.onMouseOverMedia}
                    onMouseOut={this.onMouseOutMedia}
                  />
                );
                break;
              case 'image':
                return (
                  <ImageMedia
                    key={`media-${item.id || item.upload_at}`}
                    item={item}
                    data={item.media_data}
                    onMouseOver={this.onMouseOverMedia}
                    onMouseOut={this.onMouseOutMedia}
                  />
                );
                break;
              case 'video':
                return (
                  <VideoMedia
                    key={`media-${item.id || item.upload_at}`}
                    item={item}
                    data={item.media_data}
                    onMouseOver={this.onMouseOverMedia}
                    onMouseOut={this.onMouseOutMedia}
                  />
                );
                break;
            }
          })}
        </FeedContainer>
      </Box>
    );
  }
}

export default MediaFeed;
