import React from 'react';
import PropTypes from 'prop-types';
import { mediaQueries } from 'styles/theme';
import styled from '@emotion/styled';

import cursorSvg from 'images/live/cursor.svg';

const CursorContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  span {
    position: absolute;
    bottom: 0;
    font-size: 1rem;
    white-space: nowrap;
    transition: transform 0.2s ease-in-out;

    &.nick-left {
      transform: translateX(calc(-100% - 31px));
      ${mediaQueries.sm} {
        transform: translateX(calc(-100% - 40px));
      }
    }
  }

  img {
    width: 18px;
    height: 26px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));

    ${mediaQueries.sm} {
      width: 25px;
      height: 40px;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    }
  }
`;

const Cursor = props => {
  const { id, nick, x } = props;

  return (
    <CursorContainer id={id} style={{ left: '50%', top: '50%' }}>
      <img src={cursorSvg} width="21" height="30" alt="Cursor" />
      <span className={x > 90 ? 'nick-left' : ''}>
        {nick !== 'Anonymous' ? nick.toUpperCase() : ''}
      </span>
    </CursorContainer>
  );
};

Cursor.propTypes = {
  id: PropTypes.string,
  nick: PropTypes.string,
  x: PropTypes.number
};

export default Cursor;
