import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { mediaQueries } from 'styles/theme';

import SocketIO from 'socket.io-client';

import { Box } from 'components';
import Cursor from 'components/live/Cursor';

const CursorContainer = styled.div`
  position: fixed;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 31px;
  z-index: 1002;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;

  ${mediaQueries.sm} {
    bottom: 45px;
  }
`;

const OfflineContainer = styled(Box)``;
class OnlineFeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      socket_server: process.env.GATSBY_LIVE_SOCKET_SERVER || '',
      socket_status: 'disconnected',
      clients: {}
    };

    this.socket = null;
    this.userConnected = this.userConnected.bind(this);
    this.userUpdated = this.userUpdated.bind(this);
    this.userDisconnected = this.userDisconnected.bind(this);

    this.easedClients = {};
  }

  componentDidUpdate(prevProps) {
    let cursorEl;
    for (const clientKey in this.easedClients) {
      cursorEl = document.body.querySelector(
        `#cursor-${this.easedClients[clientKey].client_id}`
      );
      this.easedClients[clientKey].cursorEl = cursorEl;
    }

    const {
      initConnection,
    } = this.props;
    if (prevProps.initConnection !== initConnection && initConnection) {
      this.initSocket();
    }
  }

  initSocket() {
    if (this.state.socket === '') return;

    this.socket = SocketIO(this.state.socket_server)
      .on('connected', () => console.log('Socket Server Connected'))
      .on('error', e => console.log('connection error', e));

    this.socket.on('user_init', this.userConnected);
    this.socket.on('user_update', this.userUpdated);
    this.socket.on('user_disconnect', this.userDisconnected);

    this.rafAnimate = requestAnimationFrame(this.animate);
  }

  componentWillUnmount() {
    this.socket.disconnect();
    cancelAnimationFrame(this.rafAnimate);
  }

  animate = () => {
    const { clients } = this.state;
    const cursorEasing = 3;

    let client;
    let easedClient;
    let clientX = 0;
    let clientY = 0;
    let i = 0;
    for (const clientKey in this.easedClients) {
      client = clients[clientKey];
      easedClient = this.easedClients[clientKey];

      if (easedClient.easedX < client.x) {
        clientX = (client.x - easedClient.easedX) / cursorEasing;
      } else {
        clientX = (easedClient.easedX - client.x) / (cursorEasing * -1);
      }
      easedClient.easedX += clientX;

      if (easedClient.easedY < client.y) {
        clientY = (client.y - easedClient.easedY) / cursorEasing;
      } else {
        clientY = (easedClient.easedY - client.y) / (cursorEasing * -1);
      }
      easedClient.easedY += clientY;

      if (easedClient.cursorEl) {
        easedClient.cursorEl.style.left = `${easedClient.easedX}%`;
        easedClient.cursorEl.style.top = `${easedClient.easedY}%`;
      }
    }
    this.rafAnimate = requestAnimationFrame(this.animate);
  };

  userConnected(client) {
    const { clients } = this.state;
    clients[client.client_id] = client;
    this.easedClients[client.client_id] = {
      ...client,
      easedX: 50,
      easedY: 50
    };
    this.setState({ clients });
  }

  userUpdated(client) {
    const { clients } = this.state;
    const updatedClientId = client.client_id;
    if (!this.easedClients[updatedClientId]) {
      this.easedClients[updatedClientId] = {
        ...client,
        easedX: 50,
        easedY: 50
      };
    }
    clients[updatedClientId] = client;
    this.setState({ clients });
  }

  userDisconnected(client) {
    const { clients } = this.state;
    delete clients[client.client_id];
    delete this.easedClients[client.client_id];

    this.setState({ clients });
  }

  render() {
    const { clients } = this.state;
    const { hidden } = this.props;

    const clientKeys = Object.keys(clients);
    return (
      <CursorContainer
        gridColumn="span 12"
        textAlign="center"
        style={{
          display: hidden ? 'none' : 'flex'
        }}
      >
        {clientKeys.length > 0 ? (
          clientKeys.map(client_id => {
            const client = clients[client_id];

            return (
              <Cursor
                key={client_id}
                id={`cursor-${client_id}`}
                nick={client.nick}
                x={client.x}
              />
            );
          })
        ) : (
            <OfflineContainer textAlign="center">
              NO ONE'S ONLINE
            </OfflineContainer>
          )}
      </CursorContainer>
    );
  }
}

export default OnlineFeed;
